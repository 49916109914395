const product = [
  {
    title: '天馈线分析仪航空套件',
    desc: '天馈线分析仪航空套件是针对航空器天馈线系统性能监测和故障诊断的一套简单易行的解决方案。我公司以BIRD SK-6000-TC或SK-4500-TC单端口天馈线分析仪为基础，以简单易测为理念，以用户痛点为靶点，开发对TCAS/LRRA/ATC/GPS/VHF等机载天馈线系统进行适配的天馈线系统航空套件，实现一套设备测量整架飞机天馈线系统驻波比等参数。并可帮助客户或由客户建立天馈线健康监测系统，定期上传监测数据，及时发现性能衰退的天馈线系统，提前做出预警，消除故障隐患；也可用于AOG排故，快速定位故障部件，避免误换其他设备造成浪费。我们的愿景：普及天馈线测试仪（射频万用表）在民航领域的应用，让其与万用表一样简单易用，为客户创造价值。',
    tableTitle: 'SK-6000-TC或SK-4500TC 天馈线分析仪（BIRD原厂件）',
    img1: require('../assets/images/productTable/1_1.png'),
    img2: require('../assets/images/productTable/1_2.png'),
    icon: require('../assets/images/icon3.jpg'),
    swiperArr: [
      { url: require('../assets/images/productDetail/page1_1.png') },
      { url: require('../assets/images/productDetail/page1_2.png') },
      { url: require('../assets/images/productDetail/page1_3.png') },
    ],
  },
  {
    title: 'TCAS天馈线系统各天线单元驻波比测试解决方案',
    desc: '我公司为TCAS天线专门设计的4W4转接器使得TCAS天馈线系统测试变得易测、可行、准确，并最大限度减少因测试导致对机载航空射频接头的寿命损耗，有效避免AOG，保障航班正常运营。',
    img1: require('../assets/images/productTable/2.png'),
    icon: require('../assets/images/icon5.jpg'),
    swiperArr: [
      { url: require('../assets/images/productDetail/page2_1.jpg') },
      { url: require('../assets/images/productDetail/page2_2.jpg') },
      { url: require('../assets/images/productDetail/page1_3.png') },
    ],
  },
  {
    title: 'GPS天馈线系统驻波比和载噪比测试解决方案',
    desc: '我公司提供的射频偏置器极佳的射频性能和安全简单的9V干电池DC3.5*1.35标准接口供电方案，支持有源GPS天线驻波比测试，帮助客户完成对有源GPS天馈线系统的在性能评估；同时利用配套的GPS接收器和偏置器，可实时查看从机载GPS天馈线系统获取的载噪比C/N数据，用于甄别飞机所在位置的GPS信号是否正常、GPS天线内部的放大器是否工作正常，确保导航数据准确，稳定。',
    img1: require('../assets/images/productTable/3.png'),
    icon: require('../assets/images/icon4.jpg'),
    swiperArr: [
      { url: require('../assets/images/productDetail/page3_1.png') },
      { url: require('../assets/images/productDetail/page3_2.png') },
    ],
  },
  {
    title: 'SIZE1/SIZE5/TYPE C航空标准接口转SMA射频转接器和柔性稳幅稳相测试线缆',
    desc: '严格按照航空规范设计制作SIZE1/SIZE5/C型SMA转接器，测试级的柔性稳幅稳相线缆，增配的SMA校准器，能最大限度的减少测试附件引入的误差，精准的反馈实际机载天馈线系统的性能。',
    img1: require('../assets/images/productTable/4_1.png'),
    img2: require('../assets/images/productTable/4_2.png'),
    icon: require('../assets/images/icon3.jpg'),
    swiperArr: [
      { url: require('../assets/images/productDetail/page4_1.jpg') },
      { url: require('../assets/images/productDetail/page4_2.jpg') },
    ],
  },
  {
    title: '射频转接器附件包',
    desc: 'ET-A1RFKIT附件包对各个射频转接器做了一目了然的标注， 可以准确快捷的定位和清点射频头，提高工作效率；可选的双套射 频转接器，一套出现损耗需要维护更换时，也能保证设备不停工；同时配置的公母转接器和SMA负载，可以随时知晓转接器是否出现了性能下降不再适用的情况，避免测试数据失真，并覆盖了所有机载通信/导航系统的天馈线系统。',
    img1: require('../assets/images/productTable/5.png'),
    icon: require('../assets/images/icon5.jpg'),
    swiperArr: [
      { url: require('../assets/images/productDetail/page5_1.jpg') },
      { url: require('../assets/images/productDetail/page5_2.jpg') },
      { url: require('../assets/images/productDetail/page5_3.jpg') },
    ],
  },
  {
    title: 'FH-AV-KIT 与 ET-6000KIT 套件对照表 ',
    desc: '',
    img1: require('../assets/images/productTable/6_1.png'),
    img2: require('../assets/images/productTable/6_2.png'),
    icon: require('../assets/images/icon4.jpg'),
    swiperArr: [
      { url: require('../assets/images/productDetail/page6_1.png') },
      { url: require('../assets/images/productDetail/page6_2.jpg') },
    ],
  },
]

export default product
