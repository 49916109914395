<template>
  <div>
    <vHeader></vHeader>
    <!-- 内页BANNER-->
    <section class="listBanner listBan">
      <h3>
        产品中心
      </h3>
    </section>

    <p class="breadCrumb container">
      <i class="el-icon-location-outline"></i>
      <span>您的位置：</span><router-link to="/" class="link">首页</router-link> →
      <span class="bc">{{productObj.title}}</span>
    </p>
    <article class="listWrap pd70">
      <div class="container hide">
        <div class="proShow_l fl">
          <div class="proShow_box1 hide">
            <div class="gallery fl">
              <!-- 图片切图 -->
              <!-- <div class="swiper-container gallery-top swiper-container-horizontal">
                <ul class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px); transition-duration: 0ms;">
                  <li class="swiper-slide swiper-slide-active" style="width: 419px;"><span class="pImg"><img
                        src="../../assets/upload/banner1.jpg" alt="RF Power Meter 4421A"></span></li>
                  <li class="swiper-slide swiper-slide-next" style="width: 419px;"><a
                      href="/uploads/202003/5e66f4de40f4e.jpg" class="pImg"><img src="../../assets/upload/banner1.jpg"
                        alt="4421A 1.jpg"></a></li>
                </ul>
              </div> -->
              <div class="swiper-main">
                <el-carousel
                  height="390px"
                  :autoplay="false"
                  :loop="false"
                  arrow="never"
                  class="swiper-box"
                  ref="carouselSwiper"
                  indicator-position="none"
                >
                  <el-carousel-item v-for="item in productObj.swiperArr" :key="item.url">
                    <img :src="item.url" alt="" @load="loadImg($event)">
                  </el-carousel-item>
                </el-carousel>
                <!-- Add Arrows -->
                <div class="swiper-button-prev btn" @click="swiperGo('-')">←</div>
                <div class="swiper-button-next btn" @click="swiperGo('+')">→</div>
              </div>
              <!-- 小图 -->
              <div class="swiper-container gallery-thumbs swiper-container-horizontal">
                <ul class="swiper-wrapper">
                  <li
                    class="swiper-slide pImg"
                    :class="{'active-slide': activeIndex === index}"
                    @click="swiperGo(index)"
                    v-for="(item, index) in productObj.swiperArr"
                    :key="item.url"
                  >
                    <img :src="item.url">
                  </li>
                </ul>
              </div>
            </div>
            <!--简介 -->
            <div class="proShow_R fr">
              <h3 class="title"><span>{{productObj.title}}</span></h3>
              <div class="font" v-show="productObj.desc">简介：{{productObj.desc}}</div>
              <!-- <div class="btn">
                <span class="btn-list"><i>&#xe602;</i>在线咨询</span>
                <span class="btn-list"><i>&#xe67e;</i>返回列表</span>
                <span class="btn-list wx-list" style="width:12%;"><span class="wx"></span></span>
              </div> -->
              <h3 class="tel">+86-15558242830</h3>
            </div>
          </div>
          <div class="proShow_con proShow_con1">
            <h4 class="proTie">
              <span
                :class="{'on': tabIndex === index}"
                @mouseenter="tabOne(index)"
                v-for="(item, index) in tabArr"
                :key="index"
              >{{item}}</span>
              <!-- <span class="">规格表</span>
              <span class="on">资料下载</span> -->
            </h4>
            <div class="proShow_Main hide swiper-container-horizontal swiper-container-fade">
              <div class="swiper-wrapper">
                <div v-show="tabIndex === 0" class="swiper-slide"
                  style="width: 781px;">
                  <p class="MsoNormal">
                    <b><span style="font-family:等线;color:#005696;font-size:26pt;line-height:2.5;"><span
                          style="line-height:2.5;">{{productObj.title}}</span></span></b><b><span
                        style="font-family:等线;color:#005696;font-size:26pt;"></span></b>
                  </p>
                  <p class="MsoNormal" v-show="productObj.desc">
                    <span style="font-family:等线;color:#005696;font-size:12pt;line-height:2.5;"><span
                        style="line-height:2.5;">简述</span></span><span
                      style="font-family:等线;color:#005696;font-size:12pt;"></span>
                  </p>
                  <p class="MsoNormal" style="text-indent:24pt;">
                    <span style="font-family:等线;font-size:12pt;line-height:2.5;">
                      <span style="line-height:2.5;">{{productObj.desc}}</span>
                    </span>
                  </p>
                </div>
                <div v-show="tabIndex === 1" class="swiper-slide table-img swiper-slide-prev"
                  style="width: 781px;">
                  <img :src="productObj.img1" alt="" :class="{'margin': Number(this.$route.query.id) === 5}">
                  <img :src="productObj.img2" alt="" v-if="productObj.img2">
                </div>
                <div v-show="tabIndex === 2" class="swiper-slide proDown swiper-slide-active"
                  style="width: 781px;">

                  <dl>
                    <dt>产品手册</dt>
                    <!-- <dd><a href="javascript:void(0)" title="4421A Dataheet_EN.pdf"
                        onclick="open_loginBox();return false">4421A Dataheet_EN.pdf</a></dd> -->
                  </dl>

                </div>
              </div>
            </div>
          </div>
          <!-- <div class="showPage">
            <a href="/bandaotizhichengshepingonglvli/6-28.html"><i>&lt;</i>RF Power Sensor</a> <a>没有了<i>&gt;</i></a> <a
              href="javascript:history.go(-1)">返回</a>
          </div>
          <div class="feedback pd70">
            <h3 class="tie">在线留言</h3>
            <form id="feedback" class="feedback_form hide" name="form" action="/form/insert.html" method="post">
              <input name="formid" value="1" type="hidden">
              <input name="lang" value="cn" type="hidden">
              <p><i></i><input type="text" placeholder="姓名" name="username"></p>
              <p><i></i><input type="text" placeholder="手机" name="tel"></p>
              <p><i></i><input type="text" placeholder="公司名称" name="company"></p>
              <p><i></i><input type="text" placeholder="邮箱" name="email"></p>
              <p class="tarea"><i></i><textarea name="content" placeholder="备注"></textarea></p>
              <div class="code">
                <input class="c fl" name="verifyCode" type="text" maxlength="20" placeholder="验证码（不区分大小写）">
                <img class="fl" id="verifyImage" align="absmiddle" title="Click the refresh verification code"
                  onclick="javascript:resetVerifyCode(this);" src="/index.php?m=index&amp;a=verify">
                <input class="sub fr" type="submit" value="提交需求" id="submit">
              </div>
            </form>
          </div> -->
        </div>
        <section class="picShow_r fr">
          <div class="rightNews rightPro">
            <h3 class="title"><span>相关推荐</span></h3>
            <ul>
              <li @click="viewDetail(index)" v-for="(item, index) in product" :key="index" v-show="index !== prdIndex">
                <div class="pImg"><img
                    :src="item.swiperArr[0].url" alt="RF Power Meter 4421A"></div>
                <h4><span>{{item.title}}</span></h4>
              </li>
            </ul>
          </div>
          <div class="rightFaq">
            <h3 class="title"><span>常见问题</span></h3>
            <ul>
            </ul>
          </div>
        </section>
      </div>
    </article>
  </div>
</template>

<script>
import vHeader from '../home/Header.vue'
import product from '../../config/productDetail'

export default {
  data() {
    return {
      prdIndex: Number(this.$route.query.id),
      activeIndex: 0,
      product,
      productObj: {},
      tabIndex: 0,
      tabArr: ['产品详情', '规格表', '资料下载'],
      swiperArr: [
        { url: require('../../assets/images/productDetail/page1_1.png') },
        { url: require('../../assets/images/productDetail/page1_2.png') },
        { url: require('../../assets/images/productDetail/page1_3.png') },
      ],
    }
  },
  mounted() {
    const idx = Number(this.$route.query.id)
    this.productObj = product[idx]
  },
  methods: {
    getRoute() {
      console.log(this.$route.query.id)
      const idx = Number(this.$route.query.id)
      this.productObj = product[idx]
      this.prdIndex = Number(this.$route.query.id)
    },
    viewDetail(index) {
      this.$router.replace('/productDetail?id=' + index)
      console.log(document.scrollHeight)
      document.scrollTop = document.scrollHeight
      this.getRoute()
    },
    tabOne(index) {
      this.tabIndex = index
    },
    loadImg(event) {
      console.log(event.target.width)
      if (event.target.width > event.target.height) {
        event.target.style.width = '100%'
      } else {
        event.target.style.height = '100%'
      }
    },
    swiperGo(index) {
      if (index === '-') {
        this.$refs.carouselSwiper.prev()
      } else if (index === '+') {
        this.$refs.carouselSwiper.next()
      } else {
        this.$refs.carouselSwiper.setActiveItem(index)
      }
      console.log(this.$refs.carouselSwiper)
      this.activeIndex = this.$refs.carouselSwiper.activeIndex
    },
  },
  components: {
    vHeader,
  }
}
</script>

<style lang="scss" scoped>
.link{
  transition: all ease 0.3s;
  &:hover {
    color: #2767bf;
  }
}
.listWrap {
  overflow: hidden;
  padding: 50px 0 70px 0;
  font-size: .875rem;
}

.listBanner{
  background: url('../../assets/images/productDetail/banner1.png') no-repeat center / cover;
  height: 460px;
  position: relative;
}

.listBanner h3 {
  position: absolute;
  color: #fff;
  top: 42%;
  font-size: 2.5rem;
  text-transform: uppercase;
  width: 100%;
  font-weight: 700;
  padding-bottom: 15px;
  animation: leftPic 1s;
  max-width: 1600px;
  min-width: 360px;
  left: 50%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
  text-align: center;
}

@keyframes leftPic
{
  0%   {opacity:0;padding-top:60px;}
  100% {opacity:1;padding-top:0;}
}

.listBanner h3 span {
  display: block;
  font-size: .6em;
  font-weight: 400;
  opacity: 0.8;
  margin-top: 20px;
}

.breadCrumb {
  font-size: .875rem;
  padding: 25px 20px 25px 0;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}

.container {
  position: relative;
  min-width: 320px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.swiper-main {
  position: relative;
  border: 1px solid #ddd;
  .swiper-box {
    position: relative;
  }
  .btn {
    position: absolute;
    bottom: 40%;
    z-index: 10;
    cursor: pointer;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.swiper-box img {
  max-width: 100%;
  max-height: 100%;
}
.swiper-box ::v-deep .el-carousel__item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.picShow_r {
  width: 25%;
  .title{
    font-size: 1.429em;
    color: #333;
    border-bottom: 1px solid #d9d9d9;
    height: 40px;
    margin-bottom: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.rightNews  {
  background: #f2f2f2;
  padding: 5%;
  li{
    border-bottom: 1px dotted #bfbfbf;
    padding-bottom: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      h4 {
        color: #005aae;
      }
      img {
        transform: scale(1.1);
      }
    }
    h4 {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 1.5;
    }
  }
  .pImg{
    width: 35%;
    float: left;
    margin-right: 20px;
    border: 1px solid #ddd;
    position: relative;
    display: block;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      // position: absolute;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      // max-width: 100%;
      // max-height: 100%;
      transition: all 0.5s;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.rightFaq {
  background: #f2f2f2;
  padding: 5%;
  margin-top: 20px;
}
.breadCrumb .back {
  float: right;
  position: relative;
  color: #005aae;
  border: 1px solid #005aae;
  line-height: 26px;
  width: 90px;
  text-align: center;
}
.breadCrumb .back i {
  font-family: "iconfont";
  margin-right: 5px;
}
.swiper-container {
  margin-top: 10px;
}

.breadCrumb .back:hover {
  background: #005aae;
  color: #fff;
}

.proShow_box1 {
  overflow: hidden;
  margin-bottom: 50px;
  background: #fff;
}

.proShowTop {
  overflow: hidden;
  margin-bottom: 50px;
}

.proShow_l {
  width: 70%;
}

.gallery {
  width: 54%;
  position: relative;
}

.gallery .gallery-top {
  position: relative;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.gallery .gallery-top .pImg {
  padding-top: 74%;
}

.gallery-top li a {
  position: relative;
  padding-top: 81%;
}

.gallery-thumbs {
  margin-left: 1px;
}

.gallery-thumbs .swiper-slide {
  border-right: 1px solid #bdc4ce;
  cursor: pointer;
  width: 25%;
  height: 100px;
  position: relative;
  text-align: center;
  &.active-slide::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #2767bf;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}

.gallery-thumbs li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.gallery-thumbs .on::after {
  border: 2px solid #2767bf;
}

.gallery .btn {
  background: #333;
  width: 40px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  opacity: 0;
}

.gallery .swiper-button-prev {
  left: 0;
}

.gallery .swiper-button-next {
  right: 0;
  left: auto;
}

.gallery .btn:hover {
  background: #2767bf;
}

.gallery:hover .btn {
  opacity: .8;
}

.proShow_R {
  width: 42%;
  overflow: hidden;
  line-height: 1.8;
  padding-bottom: 30px;
}

.proShow_R .title {
  margin-bottom: 20px;
  color: #333;
}

.proShow_R .title span {
  display: block;
  font-size: 1.778em;
  color: #005aae;
}

.proShow_R .font {
  min-height: 140px;
  line-height: 1.8;
}

.proShow_R .btn {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 20px 0;
  margin-bottom: 20px;
  margin-top: 10px;
  height: 90px;
}

.proShow_R .btn .btn-list {
  width: 32%;
  height: 50px;
  line-height: 50px;
  float: left;
  text-align: center;
  border: 1px solid #ccc;
  margin-right: 3%;
  cursor: pointer;
}
.wx-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.proShow_R .btn i {
  font-family: "iconfont";
  position: relative;
  margin-right: 5px;
  font-style: normal;
  font-size: 1.286rem;
  line-height: 1;
  top: 2px;
}

.proShow_R .btn .btn-list:hover {
  background: #005aae;
  color: #fff;
  border: 1px solid #0a3a8c;
}

.proShow_R .tel {
  font-size: 1.571em;
  color: #191919;
  font-family: Arial;
  font-weight: bold;
  background: url('../../assets/images/productDetail/zxtel.png') no-repeat left 10px;
  padding-left: 25px;
}

.proShow_R .tel span {
  margin-left: 15px;
}

.proTie {
  height: 45px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 1.143em;
  margin-bottom: 25px;
}

.proTie span {
  display: inline-block;
  height: 45px;
  width: 125px;
  text-align: center;
  background: #efefef;
  line-height: 45px;
  margin-right: 1px;
  cursor: pointer;
}

.proTie .on {
  background: #084598;
  color: #fff;
}

.proShow_Main {
  overflow: hidden;
  margin-bottom: 30px;
  font-size: 16px;
  table {
    line-height: 1.5;
  }
}

.gallery-thumbs.swiper-container .swiper-slide {
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.proShow_R .wx {
  float: right;
  width: 26px;
  height: 24px;
  background: url('../../assets/images/productDetail/topIcon.png') no-repeat;
  position: relative;
  cursor: pointer;
  position: relative;
}

.proShow_R .wx img {
  position: absolute;
  background: #f1f1f1;
  top: -30px;
  color: #666;
  width: 100px;
  padding: 5px;
  right: 30px;
  display: none;
  max-width: initial;
}


.proDown dl {
  margin-bottom: 25px;
  overflow: hidden;
}

.proDown dt {
  font-size: 1.286em;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
  line-height: 25px;
}

.proDown dt::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 25px;
  background: #084598;
}

.proDown dd {
  float: left;
  width: 50%;
  font-size: 1.143em;
  line-height: 36px;
  margin-bottom: 8px;
}

.proDown dd a {
  background: url('../../assets/upload/banner1.jpg') no-repeat left center;
  padding-left: 70px;
  display: inline-block;
}
.a-flex{
  display: flex;
  align-items: center;
}
.left-a1 {
  flex: 1;
}
.left-a2 {
  width: 160px;
  border-left: 1px solid #fff;
  p {
    border-bottom: 1px solid #fff;
    &:last-child {
      border-bottom: none;
    }
  }
}
.right-m {
  p {
    border-bottom: 1px solid #fff;
    &:last-child {
      border-bottom: none;
    }
  }
}
.table-img {
  img {
    display: block;
  }
  .margin{
    margin-left: 1px;
  }
}
</style>
