<template>
  <div class="header">
    <div class="head clearfix">
      <div class="logo">
        <router-link to="/">
          <img src="../../assets/images/logo.png" alt="易测电子科技" />
        </router-link>
      </div>
      <div class="nav_m">
        <div class="n_icon">导航栏</div>
        <ul class="nav clearfix">
          <li class="now">
            <router-link to="/home" replace>首页</router-link>
          </li>
          <li>
            <router-link to="/login" replace>控制台</router-link>
          </li>
          <li>
            <a href="product.html">产品页面</a>
          </li>
          <li>
            <a href="friend.html">合作伙伴</a>
            <div class="er">
              <div class="er_m">
                <i>&nbsp;</i>
                <ul>
                  <li><a href="">供应商</a></li>
                  <li><a href="">主要客户</a></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <a href="contact.html">联系我们</a>
            <div class="er">
              <div class="er_m">
                <i>&nbsp;</i>
                <ul>
                  <li><a href="">联系我们</a></li>
                  <li><a href="">客户留言</a></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/thems.css";
</style>
